import { useSnackbarStore } from '~/stores/snackbar'
import type { ErrorT } from '~/types'

export const useNotificationStore = defineStore('notificationStore', () => {
    const config = useRuntimeConfig()
    const headers = computed(() => { return useAuthStore().headers })
    const notifications = ref(<any>[])
    const isPending = ref(false)
    const cacheBuster = ref(0)
    
    const { setSnackbar } = useSnackbarStore()
    const { showError } = useAPI()

    const item_id = ref<number>(0)
    const messageSettings = ref<any>({ user_id: 0, message: '' })

    function $reset(): void {
        notifications.value = []
        messageSettings.value = { user_id: 0, message: '' }
        isPending. value = false
    }

    const method = ref<number>(4)
    const methods = ref<Array<{}>>([])

    const methodSettings = computed(() => {
        return {
            notification_method: method.value,
            email: '',
            phone: ''
        }
    })

    const apiUrl = computed(() => `${config.public.API_URL}/api/notifications/`)
    const apiUrl_dismiss = computed(() => `${config.public.API_URL}/api/notifications/${item_id.value}`)
    const apiUrl_getMethod = computed(() => `${config.public.API_URL}/api/notification/getmethods/` )
    const apiUrl_setMethod = computed(() => `${config.public.API_URL}/api/user/setnotification/`)
    const apiUrl_sendMessage = computed(() => `${config.public.API_URL}/api/notification/admintoapprentice`)
    const apiUrl_sendGroupMessage = computed(() => `${config.public.API_URL}/api/notification/admintogroup`)

    const { data: fetchData, error: fetchError, execute: execFetch } = useFetch(() => { return apiUrl.value }, {
        method: 'GET',
        baseURL: config.public.API_URL,
        headers: headers,
        immediate: false,
        watch: false,
    })
    async function fetchAll(): Promise<void> {
        console.log('notificationStore.fetchAll()')
        if (isPending.value) return

        isPending.value = true
        cacheBuster.value += 1

        await execFetch()

        if (fetchError.value) {
            console.log('notificationStore.fetchAll().fetchError:', fetchError.value as ErrorT)
            showError(fetchError.value as ErrorT)
        }
        else if (fetchData.value) {
            console.log('notificationStore.fetchAll().fetchData:', fetchData.value)
            notifications.value = fetchData.value
        }

        isPending.value = false
    }

    const { data: getMethodsData, error: getMethodsError, execute: execGetMethods } = useFetch(() => { return apiUrl_getMethod.value}, {
            method: 'GET',
            baseURL: config.public.API_URL,
            headers: headers,
            immediate: false,
            watch: false,
         })
    async function getMethods(): Promise<void> {
        console.log('notificationStore.getMethods()')
        if (isPending.value) return

        isPending.value = true
        cacheBuster.value += 1

        await execGetMethods()

        if (getMethodsError.value) {
            console.log('notificationStore.getMethods().getMethodsError:', getMethodsError.value as ErrorT)
            showError(getMethodsError.value as ErrorT)
        }
        else if (getMethodsData.value) {
            methods.value = getMethodsData.value as Array<{}>
        }

        isPending.value = false
    }

    const { data: setMethodData, error: setMethodError, execute: execSetMethod } = useFetch(() => { return apiUrl_setMethod.value }, {
        method: 'POST',
        baseURL: config.public.API_URL,
        headers: headers,
        body: methodSettings,
        immediate: false,
        watch: false,
    })
    async function setMethod(email: string, phone: string): Promise<boolean> {
        console.log('notificationStore.saveMethod()')
        if (isPending.value) return false

        if (method.value === 1 || method.value === 3 ) {
            if (email.trim().length < 5 || !/.+@.+\..+/.test(email.trim())) {
                setSnackbar({
                   type: `error`,
                   text: `Please enter a valid E-mail address.`,
                })
                return false
            }
            methodSettings.value.email = email.trim()
        }

        phone = phone.trim().replace(/\D/g, '');

        if (method.value === 2 || method.value === 3 ) {
            if (phone === null || phone.trim().length !== 10 || !/^[2-9][0-9]{2}[2-9][0-9]{6}$/.test(phone)) {
                setSnackbar({
                   type: `error`,
                   text: `Please enter 10-digit phone number.`,
                })
                return false
            }
            methodSettings.value.phone = phone.trim()
        }

        isPending.value = true
        cacheBuster.value += 1
        
        let success = false

        await execSetMethod()

        if (setMethodError.value) {
            console.log('notificationStore.setMethod().setMethodError:', setMethodError.value as ErrorT)
            setSnackbar({
               type: `error`,
               text: `Notification settings could not be updated.`,
            })
        }
        else if (setMethodData.value) {
            console.log('notificationStore.setMethod().setMethodData:', setMethodData.value)
            setSnackbar({
               type: `success`,
               text: `Notification settings successfully updated!`,
            })
            success = true
        }

        isPending.value = false
        return success
    }

    const { data: dismissData, error: dismissError, execute: execDismiss } = useFetch(() => { return apiUrl_dismiss.value }, {
        method: 'DELETE',
        baseURL: config.public.API_URL,
        headers: headers,
        immediate: false,
        watch: false,
     })
    async function dismiss(id: number): Promise<boolean> {
        console.log('notificationStore.dismiss()')
        if (isPending.value) return false

        isPending.value = true
        cacheBuster.value += 1
        item_id.value = id
        
        let success = false

        await execDismiss()

        if (dismissError.value) {
            console.log('notificationStore.dismiss().dismissError:', dismissError.value as ErrorT)
            setSnackbar({
               type: `error`,
               text: `Notification settings could not be dismissed.`,
            })
        }
        else if (dismissData.value) {
            console.log('notificationStore.dismiss().dismissData:', setMethodData.value)
            setSnackbar({
               type: `success`,
               text: `Notification settings successfully dismissed!`,
            })
            success = true
        }

        isPending.value = false
        return success
    }

    const { data: sendMessageData, error: sendMessageError, execute: execSendMessage } = useFetch(() => { return apiUrl_sendMessage.value }, {
        method: 'POST',
        baseURL: config.public.API_URL,
        headers: headers,
        body: messageSettings,
        immediate: false,
        watch: false,
    })
    async function sendMessage(user_id: number, msg: string): Promise<boolean> {
        console.log('notificationStore.sendMessage()')
        if (isPending.value) return false

        isPending.value = true
        cacheBuster.value += 1
        
        let success = false

        messageSettings.value = { user_id: user_id, message: msg }

        await execSendMessage()

        if (sendMessageError.value) {
            console.log('notificationStore.sendMessage().sendMessageError:', sendMessageError.value as ErrorT)
            setSnackbar({
               type: `error`,
               text: `Message could not be sent.`,
            })
        }
        else if (sendMessageData.value) {
            console.log('notificationStore.sendMessage().sendMessageData:', sendMessageData.value)
            setSnackbar({
               type: `success`,
               text: `Message successfully sent!`,
            })
            success = true
        }

        isPending.value = false
        return success
    }

    const { data: sendGroupMessageData, error: sendGroupMessageError, execute: execSendGroupMessage } = useFetch(() => { return apiUrl_sendGroupMessage.value }, {
        method: 'POST',
        baseURL: config.public.API_URL,
        headers: headers,
        body: messageSettings,
        immediate: false,
        watch: false,
    })
    async function sendGroupMessage(group_id: number, msg: string): Promise<boolean> {
        console.log('notificationStore.sendMessage()')
        if (isPending.value) return false

        isPending.value = true
        cacheBuster.value += 1
        
        let success = false

        messageSettings.value = { group_id: group_id, message: msg }

        await execSendGroupMessage()

        if (sendGroupMessageError.value) {
            console.log('notificationStore.sendGroupMessage().sendGroupMessageError:', sendGroupMessageError.value as ErrorT)
            setSnackbar({
               type: `error`,
               text: `Message could not be sent.`,
            })
        }
        else if (sendGroupMessageData.value) {
            console.log('notificationStore.sendGroupMessage().sendGroupMessageData:', sendGroupMessageData.value)
            setSnackbar({
               type: `success`,
               text: `Message successfully sent!`,
            })
            success = true
        }

        isPending.value = false
        return success
    }

    return {
        notifications,
        method,
        methods,
        isPending,
        fetchAll,
        getMethods,
        setMethod,
        dismiss,
        sendMessage,
        sendGroupMessage,
        $reset
    }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useFrequencyStore, import.meta.hot))
}